<template>
  <div class="ele-body">
    <el-tabs v-model="activeName" >
      <el-tab-pane label="司机排名" name="first">
        <el-card shadow="never">
            <!-- 搜索表单 -->
            <el-form :model="table.where" class="ele-form-search" style="display:flex;flex-wrap: wrap;"
                    @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
                <el-form-item label="司机姓名:" label-width="80px" >
                    <el-input v-model="table.where.name" placeholder="请输入司机姓名" class="input150" clearable/>
                </el-form-item>
                <el-form-item label="手机号:" label-width="70px">
                    <el-input
                        v-model="table.where.phone" placeholder="请输入司机手机号" class="drop-down-fourth" clearable/>
                </el-form-item>
              <el-form-item label="省:" label-width="35px" class="w-170">
                <el-select v-model="table.where.pid"  @change="handleChangeProv(table.where.pid)" placeholder='请选择省' clearable>
                  <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="市:" label-width="35px" class="w-150">
                <el-select v-model="table.where.cid"  @change="handleChangeCity(table.where.cid)" placeholder='请选择市' clearable>
                  <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="县/区:" label-width="62px" class="w-200">
                <el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
                  <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name"></el-option>
                </el-select>
              </el-form-item>
                <el-form-item label="开始时间:" label-width="80px">
                    <el-date-picker v-model="table.where.start_time" type="date" placeholder="最早订单时间" clearable>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间:" label-width="80px">
                    <el-date-picker v-model="table.where.end_time" type="date" placeholder="最晚订单时间" clearable>
                    </el-date-picker>
                </el-form-item>
                <el-form-item style="width: 400px;">
                    <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon search-margin-left">搜索</el-button>
                    <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
                    <download   style="margin-left: 0" class="ele-btn-icon ml-20"  label="导出" url="driver/driverRankingExportExcel"
                                :params="exportOrderParams()"/>
<!--                    <el-button type="primary" @click="exportExcel" v-if="permission.includes('sys:order:export')" icon="el-icon-download" class="ele-btn-icon">导出</el-button>-->
                </el-form-item>
            </el-form>
            <!-- 数据表格 -->
            <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row :stripe=true>
                <template slot-scope="{index}">
                    <el-table-column type="selection" width="45" align="center" fixed="left"/>
                    <el-table-column type="index" :index="index" label="序号" width="60" align="center" fixed="left" show-overflow-tooltip/>
                  <el-table-column prop="name" label="姓名"  show-overflow-tooltip min-width="120"/>
                  <el-table-column prop="phone" label="手机号"  show-overflow-tooltip min-width="120"/>
                  <el-table-column prop="driver_area" label="所属地区"  show-overflow-tooltip min-width="220"/>
                  <el-table-column label="注册时间"  show-overflow-tooltip min-width="160">
                    <template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
                  </el-table-column>
<!--                  <el-table-column label="最后活跃时间"  show-overflow-tooltip min-width="160">-->
<!--                    <template slot-scope="{row}">{{ row.active_time*1000 | toDateString }}</template>-->
<!--                  </el-table-column>-->
                  <el-table-column prop="active_time" label="最后活跃时间"  show-overflow-tooltip min-width="145"/>
                  <el-table-column prop="today_time" label="今日在线时长(分钟)"  show-overflow-tooltip min-width="145"/>
                  <el-table-column prop="yesterday_time" label="昨日在线时长(分钟)"  show-overflow-tooltip min-width="145"/>
                  <el-table-column prop="total_time" label="累计在线时长(分钟)"  show-overflow-tooltip min-width="145"/>
                  <el-table-column prop="order_total" label="订单量(笔)"  show-overflow-tooltip min-width="120"/>
                  <el-table-column prop="effective_order_total" label="有效订单量(笔)"  show-overflow-tooltip min-width="120"/>
                  <el-table-column prop="cancel_order_count" label="无效订单量(笔)"  show-overflow-tooltip min-width="120"/>
                  <el-table-column prop="money" label="交易额(元)"  show-overflow-tooltip min-width="145"/>
                </template>
            </ele-data-table>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="跑腿排名" name="second">
        <el-card shadow="never">
            <!-- 搜索表单 -->
            <el-form :model="table1.where" class="ele-form-search" style="display:flex;flex-wrap: wrap;"
                    @keyup.enter.native="$refs.table1.reload()" @submit.native.prevent>
                <el-form-item label="跑腿姓名:" label-width="80px">
                    <el-input v-model="table1.where.driver_name" placeholder="请输入跑腿姓名" class="input150" clearable/>
                </el-form-item>
                <el-form-item label="手机号:" label-width="70px">
                    <el-input
                        v-model="table1.where.driver_phone" placeholder="请输入跑腿手机号" class="drop-down-fourth" clearable/>
                </el-form-item>
              <el-form-item label="省:" label-width="35px" class="w-170">
                <el-select v-model="table1.where.pid"  @change="handleChangeProv1(table1.where.pid)" placeholder='请选择省' clearable>
                  <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="市:" label-width="35px" class="w-150">
                <el-select v-model="table1.where.cid"  @change="handleChangeCity1(table1.where.cid)" placeholder='请选择市' clearable>
                  <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="县/区:" label-width="62px" class="w-200">
                <el-select v-model="table1.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
                  <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name"></el-option>
                </el-select>
              </el-form-item>
                <el-form-item label="开始时间:" label-width="80px" >
                    <el-date-picker v-model="table1.where.start_time" type="date" placeholder="最早订单时间" clearable>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间:" label-width="80px">
                    <el-date-picker v-model="table1.where.end_time" type="date" placeholder="最晚订单时间" clearable>
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="$refs.table1.reload()" icon="el-icon-search" class="ele-btn-icon search-margin-left">搜索</el-button>
                    <el-button @click="(table1.where={})&&$refs.table1.reload()">重置</el-button>
                    <download   style="margin-left: 0" class="ele-btn-icon ml-20"  label="导出" url="driver/driverRankingExportExcel"
                                :params="exportOrderParams1()"/>
<!--                    <el-button type="primary" @click="exportExcel" v-if="permission.includes('sys:order:export')" icon="el-icon-download" class="ele-btn-icon">导出</el-button>-->
                </el-form-item>
            </el-form>
            <!-- 数据表格 -->
            <ele-data-table ref="table1" :config="table1" :choose.sync="choose1" height="calc(100vh - 315px)" highlight-current-row :stripe=true>
                <template slot-scope="{index}">
                    <el-table-column type="selection" width="45" align="center" fixed="left"/>
                    <el-table-column type="index" :index="index" label="序号" width="60" align="center" fixed="left" show-overflow-tooltip/>
                  <el-table-column prop="name" label="姓名"  show-overflow-tooltip min-width="120"/>
                  <el-table-column prop="phone" label="手机号"  show-overflow-tooltip min-width="120"/>
                  <el-table-column prop="driver_area" label="所属地区"  show-overflow-tooltip min-width="220"/>
                  <el-table-column label="注册时间"  show-overflow-tooltip min-width="160">
                    <template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
                  </el-table-column>
                  <el-table-column label="最后活跃时间"  show-overflow-tooltip min-width="160">
                    <template slot-scope="{row}">{{ row.active_time*1000 | toDateString }}</template>
                  </el-table-column>
                  <el-table-column prop="today_time" label="今日在线时长(分钟)"  show-overflow-tooltip min-width="145"/>
                  <el-table-column prop="yesterday_time" label="昨日在线时长(分钟)"  show-overflow-tooltip min-width="145"/>
                  <el-table-column prop="total_time" label="累计在线时长(分钟)"  show-overflow-tooltip min-width="145"/>
                  <el-table-column prop="order_total" label="订单量(笔)"  show-overflow-tooltip min-width="155"/>
                  <el-table-column prop="effective_order_total" label="有效订单量(笔)"  show-overflow-tooltip min-width="145"/>
                  <el-table-column prop="cancel_order_count" label="无效订单量(笔)"  show-overflow-tooltip min-width="145"/>
                  <el-table-column prop="money" label="交易额(元)"  show-overflow-tooltip min-width="145"/>
                </template>
            </ele-data-table>
        </el-card>
      </el-tab-pane>

      <el-tab-pane label="商家排名" name="third">
        <el-card shadow="never">
            <!-- 搜索表单 -->
            <el-form :model="table2.where" class="ele-form-search" style="display:flex;flex-wrap: wrap;"
                    @keyup.enter.native="$refs.table2.reload()" @submit.native.prevent>
                <el-form-item label="商家姓名:" label-width="80px">
                    <el-input v-model="table2.where.true_name" placeholder="请输入商家姓名" class="drop-down-third" clearable/>
                </el-form-item>
                <el-form-item label="手机号:" label-width="70px">
                    <el-input
                        v-model="table2.where.phone" placeholder="请输入商家手机号" class="drop-down-fourth" clearable/>
                </el-form-item>
              <el-form-item label="省:" label-width="35px" class="w-170">
                <el-select v-model="table2.where.pid"  @change="handleChangeProv2(table2.where.pid)" placeholder='请选择省' clearable>
                  <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="市:" label-width="35px" class="w-150">
                <el-select v-model="table2.where.cid"  @change="handleChangeCity2(table2.where.cid)" placeholder='请选择市' clearable>
                  <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="县/区:" label-width="62px" class="w-200">
                <el-select v-model="table2.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
                  <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name"></el-option>
                </el-select>
              </el-form-item>
                <el-form-item label="开始时间:" label-width="80px" >
                    <el-date-picker v-model="table2.where.start_time" type="date" placeholder="最早订单时间" clearable>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间:" label-width="80px" >
                    <el-date-picker v-model="table2.where.end_time" type="date" placeholder="最晚订单时间" clearable>
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="$refs.table2.reload()" icon="el-icon-search" class="ele-btn-icon search-margin-left">搜索</el-button>
                    <el-button @click="(table2.where={})&&$refs.table2.reload()">重置</el-button>
                    <download   style="margin-left: 0;width: 100px;" class="ele-btn-icon ml-20"  label="导出" url="driver/driverRankingExportExcel"
                          
                                :params="exportOrderParams2()"/>
<!--                    <el-button type="primary" @click="exportExcel" v-if="permission.includes('sys:order:export')" icon="el-icon-download" class="ele-btn-icon">导出</el-button>-->
                </el-form-item>
            </el-form>
            <!-- 数据表格 -->
            <ele-data-table ref="table2" :config="table2" :choose.sync="choose2" height="calc(100vh - 315px)" highlight-current-row :stripe=true>
                <template slot-scope="{index}">
                <el-table-column type="selection" width="45" align="center" fixed="left"/>
                <el-table-column type="index" :index="index" label="序号" width="60" align="center" fixed="left" show-overflow-tooltip/>
<!--                  <el-table-column prop="name" label="姓名"  show-overflow-tooltip min-width="120"/>-->
                  <el-table-column prop="true_name" label="负责人名称"  show-overflow-tooltip min-width="120"/>
                  <el-table-column prop="phone" label="手机号"  show-overflow-tooltip min-width="120"/>
                  <el-table-column prop="store_name" label="店铺名称"  show-overflow-tooltip min-width="120"/>
                  <el-table-column prop="driver_area" label="所属地区"  show-overflow-tooltip min-width="220"/>
                  <el-table-column label="注册时间"  show-overflow-tooltip min-width="160">
                    <template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
                  </el-table-column>
                  <el-table-column label="最后活跃时间"  show-overflow-tooltip min-width="160">
                    <template slot-scope="{row}">{{ row.lastlogin_time*1000 | toDateString }}</template>
                  </el-table-column>
                  <el-table-column prop="today_time" label="今日在线时长(分钟)"  show-overflow-tooltip min-width="145"/>
                  <el-table-column prop="yesterday_time" label="昨日在线时长(分钟)"  show-overflow-tooltip min-width="145"/>
                  <el-table-column prop="total_time" label="累计在线时长(分钟)"  show-overflow-tooltip min-width="145"/>
                  <el-table-column prop="order_total" label="订单量"  show-overflow-tooltip min-width="125"/>
                  <el-table-column prop="effective_order_total" label="有效订单量"  show-overflow-tooltip min-width="145"/>
                  <el-table-column prop="cancel_order_count" label="无效订单量"  show-overflow-tooltip min-width="145"/>
                  <el-table-column prop="money" label="交易额(元)"  show-overflow-tooltip min-width="145"/>
                </template>
            </ele-data-table>
        </el-card>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import download from '@/components/Download/index'
export default {
  name: "SysUser",
    components: {download},
  data() {
    return {
      table: {url: '/driver/driver_ranking', where: {type:1}},  // 表格配置
      table1: {url: '/driver/driver_ranking', where: {type:2}},  // 表格配置
      table2: {url: '/driver/driver_ranking', where: {type:3}},  // 表格配置
      // table1: {url: '/driver/index_legwork', where: {}},  // 表格配置
      // table2: {url: '/driver/index_merchant', where: {}},  // 表格配置
      choose: [],  // 表格选中数据
      choose1: [],  // 表格选中数据
      choose2: [],  // 表格选中数据
      editForm: {},  // 表单数据
      checkForm:{},
      editRules: {  // 表单验证规则                      
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
					{
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, 
						message: '请输入正确的手机号', 
						trigger: 'blur',
					}
        ],                           
        name: [
          {required: true, message: '请输入昵称', trigger: 'blur'}
        ],                     
        sex: [
          {required: true, message: '请输入性别，1男2女', trigger: 'blur'}
        ],                        
        home: [
          {required: true, message: '请输入家乡名称，对应aid', trigger: 'blur'}
        ],
                          
        money: [
          {required: true, message: '请输入余额', trigger: 'blur'}
        ],
                          
        losemoney: [
          {required: true, message: '请输入已提现/使用余额', trigger: 'blur'}
        ],
              
      },
      dialogTableVisibleCheck:false,
      dialogTableVisibleEdit:false,
      dialogTableVisibleUrgent:false,
      provArr:[],
      cityArr:[],
      districtArr:[],
      urgentList:[],
      imageUrl:false,
      headImg:'',
      activeName:'first'
    }
  },
  created(){
     this.$http.get('/common/province_list').then(res=>{
          var data=JSON.parse(res.data)
          this.provArr=data
        })
  },

  computed: {
      ...mapGetters(["permission"]),
  },
  mounted() {
  },
  methods: {

      exportOrderParams() {
          const query = this.table.where
          // const params = this.params
          const selectionIds = this.choose.map(item => {
              return item.id
          })

          return {ids: selectionIds, ...query}
      },


      exportOrderParams1() {
          const query = this.table1.where
          // const params = this.params
          const selectionIds = this.choose1.map(item => {
              return item.id
          })

          return {ids: selectionIds, ...query}
      },


      exportOrderParams2() {
          const query = this.table2.where
          // const params = this.params
          const selectionIds = this.choose2.map(item => {
              return item.id
          })

          return {ids: selectionIds, ...query}
      },

      /**
     *选择省
     **/
    handleChangeProv(e){
      /** 获取被选省份的pid**/
      let pid=''
      this.provArr.forEach(function(item){
        if(item.pid==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        let data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.table.where.cid=''
        this.table.where.aid=''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity(e){
			if(e){
      /** 获取被选市的cid**/
      let cid=''
      this.cityArr.forEach(function(item){
        if(item.cid==e){
          cid=item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list',{cid:cid}).then(res=>{
        let data=JSON.parse(res.data)
        this.districtArr=data
        /** 选择省份清空县**/
        this.table.where.aid=''
      })
			}else{
				this.table.where.pid = ''
				this.table.where.aid = ''
			}
    },
    /**
     *选择省
     **/
    handleChangeProv1(e){
      /** 获取被选省份的pid**/
      let pid=''
      this.provArr.forEach(function(item){
        if(item.pid==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        let data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.table1.where.cid=''
        this.table1.where.aid=''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity1(e){
			if(e){
      /** 获取被选市的cid**/
      let cid=''
      this.cityArr.forEach(function(item){
        if(item.cid==e){
          cid=item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list',{cid:cid}).then(res=>{
        let data=JSON.parse(res.data)
        this.districtArr=data
        /** 选择省份清空县**/
        this.table1.where.aid=''
      })
			}else{
				this.table1.where.pid = ''
				this.table1.where.aid = ''
			}
    },
    /**
     *选择省
     **/
    handleChangeProv2(e){
      /** 获取被选省份的pid**/
      let pid=''
      this.provArr.forEach(function(item){
        if(item.pid==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        let data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.table2.where.cid=''
        this.table2.where.aid=''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity2(e){
			if(e){
      /** 获取被选市的cid**/
      let cid=''
      this.cityArr.forEach(function(item){
        if(item.cid==e){
          cid=item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list',{cid:cid}).then(res=>{
        let data=JSON.parse(res.data)
        this.districtArr=data
        /** 选择省份清空县**/
        this.table2.where.aid=''
      })
			}else{
				this.table2.where.pid = ''
				this.table2.where.aid = ''
			}
    },
    /* 导出数据Excel */
    exportExcel() {
       let info = JSON.parse(JSON.stringify(this.table.where));
      this.$http
          // .get("/user/exportExcel", {'name':name,'phone':phone,'pid':pid,'cid':cid,'area_id':area_id})
          .get("/user/exportExcel", info)
          .then((res) => {
            let data = res.data;
            if (data.code == 0) {
              // 下载文件
              window.location.href = data.data;
              this.$message({
                type: "success",
                message: "导出成功",
              });
            } else {
              this.$message.error("导出失败");
            }
          })
    },                                                                                                                                                                                                                   }
}
</script>

<style scoped>
  .ele-block .el-upload-dragger {
    width: 100%;
  }
  .changeStyle{
    width:150px;margin-right:30px;
  }
  .ele-body{
    padding:15px;
  }
  .el-upload-list el-upload-list--text{display:none !important}
  /deep/.el-tabs__item{
    padding:0 20px !important;
    font-size: 15px;
  }
  /deep/.el-tabs__nav-scroll{
    background: white !important;
    padding-top: 10px;
  }
.el-input--medium /deep/.el-input__inner{
    width: 160px !important;
  }
  .el-date-editor.el-input, .el-date-editor.el-input__inner{width:160px !important}
</style>

